/*
 * @Author: 脉迈
 * @Date: 2021-11-11 15:22:38
 * @LastEditors: 脉迈
 * @LastEditTime: 2022-02-14 17:00:26
 */
 // eslint-disable-next-line
export default `
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本平台承诺保护用户的隐私。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">本《隐私政策》适用于本平台提供的用户端软件、网站、服务（以下统称为</span><span style="font-family:微软雅黑">“我们的服务”或“本平台服务”），包括但不限于适用于计算机、移动智慧终端机的产品及服务（含站外服务，例如：我们的广告服务和“通过我们的服务申请或分享”的外挂程序等）。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本隐私权政策旨在帮助您了解我们会收集哪些资料、为什么收集这些资料、会利用这些资料做什么以及我们如何保护这些资料。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">了解这些内容，对于您行使个人权利及保护您的个人信息至关重要，请您在使用本平台服务前务必认真阅读本政策。如果您未满</span><span style="font-family:微软雅黑">18周岁，请在法定监护人的陪同下阅读本《隐私政策》。如您为未成年人的法定监护人，请特别注意第七条。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">当您开始下载、访问、使用或继续使用我们的服务，都意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。我们的服务均适用本政策。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">请您注意我们将会不时地检查我们的政策，并根据需要对我们的政策进行不时更新。我们诚邀您定期登录本《隐私政策》相关页面，以确保了解我们发布的最新《隐私政策》版本。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知，如您不同意变更后的内容，您可以选择停止使用我们的服务；如您仍然继续使用我们服务的，即表示同意受经修订的本《隐私政策》的约束。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">关于我们</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本平台是指包括彩虹伽们网络有限公司及其关联公司。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">关联公司是指在现在、将来控制彩虹伽们网络有限公司、受彩虹伽们网络有限公司控制或与彩虹伽们网络有限公司处于共同控制下的公司、机构。控制指通过所有权、有投票权的股份、合同、实际运营关联或其他被依法认定的方式直接或间接地拥有影响被控制物件管理</span><span style="font-family:微软雅黑">/经营的能力。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">如您对本《隐私政策》或相关事宜有任何问题，请通过</span><span style="font-family:微软雅黑">NeyaHK2021@gmail.com与我们联系。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">目录</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">一、我们如何收集和使用信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">二、我们如何使用</span><span style="font-family:微软雅黑">&nbsp;Cookie&nbsp;和同类技术</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">三、我们可能分享、转让和公开披露的信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">四、我们如何储存和保护信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">五、我们可能向您发送的信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">六、您如何访问和管理自己的个人信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">七、广告服务</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">八、未成年人限制</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">九、通知及变更</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">十、如何联系我们</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">一、我们如何收集和使用信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们提供如下功能和服务时，可能会收集、储存和使用下列与您有关的信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如果您不提供相关信息，您可能无法注册成为我们的用户或无法享受我们提供的某些产品或服务，或者无法达到使用相关服务拟达到的效果。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）我们将通过以下途径收集和获得您的信息：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）您在注册本平台的账号或使用我们服务时，主动向我们提供的个人信息，包括个人敏感信息；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本隐私政策中涉及的个人敏感信息包括您的个人生物识别信息（包括面部特征、语音）、个人身份标识信息（包括身份证信息）、日志信息、通讯录信息、个人财产信息、个人常用设备信息、其他信息（包括个人电话号码、手机号码、行踪轨迹信息、大概地理位置、精准定位信息、网页浏览记录）</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。您同意您的个人敏感信息按本《隐私政策》所述的目的和方式来处理。相比其他个人信息，个人敏感信息可能对您的个人权益影响更大。我们在此善意的提醒您需在谨慎考虑后再向我们提供您的个人敏感信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）您通过我们的服务向其他方提供的共享信息，以及您使用我们服务时所储存的信息。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">请注意，如您在我们的服务中其他使用者可见的公开区域内上传或发布的信息中、您对其他人上传或发布的信息作出的回应中公开您的信息，该等信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的信息时，请联系我们</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）其他方分享的您的信息。亦即其他方使用我们的服务时所提供有关您的共享信息。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">4）我们获取的您的信息。您在使用我们的服务时，我们收集、汇总、记录的信息，例如</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">日志信息、个人常用设备信息、定位信息</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）我们会出于以下目的或业务场景，收集和使用您以下类型的信息：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、用户注册功能及登录，帮助您成为我们的用户</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">为便于我们为您提供服务，您需要提供基本注册或登录信息，选择填写及</span><span style="font-family:微软雅黑">/或提交使用产品或服务所必须的信息，可能</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">包括手机号码、验证码匹配结果、昵称、头像、电子邮箱位址</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等个人敏感信息，创建您的账号、用户名和密码，成为我们的注册用户。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们可能将通过发送短信验证码或通讯运营商提供的其他认证形式来验证您的身份是否有效。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您可以选择进一步完善本平台账号信息，可以向我们提供您的姓名、生日、性别、注册账号名称、昵称、密码、口令、密码保护问题、电子邮寄地址、位址位置、兴趣爱好、头像照片、手机号码、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">身份证号</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">等信息以获取更加个性化的服务。如果您提供真实有效的手机号码、淘宝账号、微信、微博、</span><span style="font-family:微软雅黑">QQ等进行绑定，当您遇到注册账号丢失、忘记密码等账号问题时，可以更便捷的找回账号和密码。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">但如果您不提供这些信息，将不会影响您使用本平台提供的基础服务，但可能会影响到您使用本平台需要进行实名认证、个性化服务等的附加功能。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如果您仅需使用浏览、搜索等基础服务，您不需要注册成为我们的使用者及提供上述信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">您还可以通过登录与我们合作的协力厂商平台的相关账户使用我们的产品与</span><span style="font-family:微软雅黑">/或服务，此时我们可能会获取您在该等协力厂商账号项下的相关信息，例如账户名称、账户昵称、好友关系等，请您在登录时仔细阅读相关登录界面的专门提示。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">此外，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在您主动注销账号时，我们将根据本平台的服务协定约定条件及相关法律法规的要求尽快使其匿名或删除您的个人信息</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。您可以联系我们处理或按照单项服务的具体指引进行操作。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、为您展示和推送商品或服务、提供搜索功能</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">为改善我们的服务、向您提供个性化的服务，我们可能会根据您的账号信息、浏览及搜索记录、点击记录、订阅信息、收藏记录、下载记录、设备信息、位置信息、订单信息、</span><span style="font-family:微软雅黑">IP位址、网络环境等信息及您在使用我们服务时提供的内容信息（包括不限于评论、跟帖、弹幕、社区等内容信息）等，提取您的浏览、搜索偏好、行为习惯、位置信息等信息作特征分析和使用者画像，以便为您提供更合适您和您的设备的定制化服务。例如，向您展现或推荐相关程度更高的搜索结果、信息流或广告及推广信息。我们可能将您的画像信息与您授权的其他方的信息结合起来，更好的满足您的需求</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。如果您不想接受我们给您推送的商业广告，您可随时通过相应产品退订功能取消。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">为了让您快速地找到您所需要的产品</span><span style="font-family:微软雅黑">/服务，我们可能会收集您使用我们的产品与/或服务的</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">日志信息</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">及</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">个人常用设备信息</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">，例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">硬件型号、设备</span><span style="font-family:微软雅黑">MAC位址、作业系统类型、软件清单、唯一设备识别码如（IMEI/androidID/IDFA/OPENUDID/GUID/IDFV、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">）等来为您提供商品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入来为您展示与您搜索内容相关联的商品。若您以游客身份搜索我们的产品</span><span style="font-family:微软雅黑">/服务，我们只会根据热销推荐向您提供产品/服务的展示。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">请您注意，您的搜索关键字信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">；只有当您的搜索关键字信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键字信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、向您提供产品或服务</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">为便于您使用我们的服务，需要您根据产品或服务的内容，选择填写及</span><span style="font-family:微软雅黑">/或提交使用产品或服务所必须的信息，</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">有时可能还需要您提供身份信息、手机号码、电话号码、位址、银行或支付交易账号等个人敏感信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态、向您报送错误等，我们会收集关于您使用我们产品或服务使用方式的信息并将这些信息进行关联。这些信息包括</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">位置信息、账号信息、身份信息、设备信息、日志信息、网络环境</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等，其中设备信息和日志信息是指：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">设备信息：</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">设备型号、作业系统版本、设备设置、设备</span><span style="font-family:微软雅黑">MAC位址、唯一设备标识码（如IMEI/android ID/IDFA/OPENUDID/GUID/IDFV、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）等软硬件特征信息</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">）、设备所在位置相关信息（例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">IP位址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">）。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">日志信息：</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">当您使用我们提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">您的搜索查询内容、浏览记录、点击记录、</span><span style="font-family:微软雅黑">IP位址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息，为了在您使用设备上为您提供一致的服务，我们会将于您各种设备（计算机、移动设备）上收集到的信息进行关联。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">匿名化处理</span><span style="font-family:微软雅黑">/去标识化是指对个人信息进行技术处理，使得个人信息主体无法被识别。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">为了帮助您更好地使用我们的产品或服务，我们会收集相关信息。例如，我们收集的</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">好友清单、群清单信息、声纹特征值信息</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。为确保您使用我们服务时能与您认识的人进行联系，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如您选择开启导入通讯录功能，我们可能对您连络人的姓名和电话号码进行加密，并仅收集加密后的信息</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在部分服务项目中，为便于向您交付商品或服务，您至少需提供</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态信息</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">。上述所有信息构成您的</span><span style="font-family:微软雅黑">“订单信息”，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务；我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过我们服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">客户服务。当您与我们联系时，为了及时有效地与您沟通，我们的在线客服和电话客服功能可能会保存您的</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">账号、电话、住址、邮箱、</span><span style="font-family:微软雅黑">IP位址、通信/通话记录和内容或您留下的其他联系方式</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、线下活动、发起退款</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您通过我们的客服或参加我们举办的活动、调研时所提交的信息。例如，您参与我们调研活动时填写的调查问卷中可能包含您的</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">姓名、电话、手机号码、位址</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您在我们的平台订购商品并通过线下活动领取商品以及</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您向我们发起退款时，您需要向我们提供您的个人信息以用于确认您的身份，例如您的身份证号、姓名</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">5、定位功能</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">当您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）您通过具有定位功能的移动设备使用我们的某些与地理位置相关的服务时，通过GPS或WiFi等方式收集的您的地理位置信息。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您可以通过移动设备上的关闭定位功能，停止对您的地理位置信息的收集。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">6、设备权限调用</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们在提供服务的过程中，可能需要您开启一些设备存取权限，例如</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">相机（摄像头）、相册（图片库）、麦克风、手机通讯录</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">等存取权限，以实现这些功能所涉及的信息的收集和使用。您也可以在设备的设置功能中随时选择关闭部分或全部权限，从而拒绝我们收集相应的个人信息。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">7、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们通过间接获得方式收集到的您的个人信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）您使用或订购协力厂商产品或服务的信息。为了给您更加便捷的生活服务，本平台可能会为您接入了丰富的协力厂商服务。您可以通过相关产品模块使用相关功能。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）当您使用本平台接入的协力厂商服务时，可能需要提交您的个人信息（部分情况下包括您的身份信息、联系方式、住宿信息、行程信息、交易信息、支付信息、账号信息、昵称、评论内容、银行账号等以及个人明确授权可以共享信息）。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您授权我们将该信息提供给为您提供服务的协力厂商，并授权我们间接向协力厂商获取相关信息。若您拒绝我们收集此类信息，我们将无法为您提供相关功能和服务，但不会影响您对产品或服务基础功能的使用。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）当您使用FB账号、Apple账号、google账号登入我们的产品或服务时，我们需要核实您的身份或绑定您的账户信息，经过您的授权将相关账号关联到我们的产品或服务中。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4）如您拒绝提供上述信息或拒绝授权，可能无法使用我们关联公司或协力厂商的相应产品或服务，或者无法展示相关信息，但不影响使用本产品或服务的基础功能。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">8、为您提供安全保障</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">请注意，为确保用户身份真实性、向您提供更好的安全保障，您需要向我们提供身份证或者护照的证件号码等个人敏感信息完成实名认证。如您拒绝提供上述信息，可能无法使用需要完成实名认证后才能提供的附加服务，但不会影响您使用浏览、搜索等基础服务。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">为提高您使用我们及我们关联公司、合作伙伴提供产品或服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本产品相关协定规则的情况，我们可能使用或整合您的使用者信息、行为信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账号状态及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">9、其他用途</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）我们可能将不定期地举办有关我们产品和服务的调查，或者围绕运营需要举办促销及抽奖活动，如果您在参与此类调查或活动时需要提供超过本政策许可的个人敏感信息，我们会事先征求您的同意。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）需进行额外说明的是，为确保流畅的产品体验，您可能会收到来自我们及其合作伙伴外部的协力厂商（下文简称“协力厂商”）提供的内容或网络连结。我们对此类协力厂商无控制权。您可选择是否访问协力厂商提供的连结、内容、产品和服务。我们无法控制协力厂商的隐私和资料保护政策，此类协力厂商不受到本政策的约束。在向协力厂商提交个人信息之前，请参见这些协力厂商的隐私保护政策。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">10、征得授权同意的例外</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）与国家安全、国防安全有关的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）与公共安全、公共卫生、重大公共利益有关的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）与犯罪侦查、起诉、审判和判决执行等有关的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">5）所收集的个人信息是您自行向社会公众公开的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">6）从合法公开披露的信息中收集个人信息的，如合法的新闻报导、政府信息公开等管道；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">7）根据您的要求签订合同所必需的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">8）用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">9）为合法的新闻报导所必需的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">11）法律法规规定的其他情形。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如我们停止运营本产品的功能或服务，我们将及时停止继续收集您个人信息的活动，将停止运营信息以通知或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">二、我们如何使用</span><span style="font-family:微软雅黑">&nbsp;Cookie和同类技术</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;（一）Cookie</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">为确保本产品正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">我们可能会在您的计算机或移动设备上存储名为</span><span style="font-family:微软雅黑">Cookie&nbsp;的小资料档案。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">Cookie通常包含标识符、网站名称以及一些号码和字符。借助于Cookie，网站及用户端能够存储您的注册账号、邮箱、密码、偏好、银行账号、网络支付和/或交易账号等资料。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">您可根据自己的偏好管理或删除</span><span style="font-family:微软雅黑">Cookie。您可以清除计算机上保存的所有&nbsp;Cookie，大部分网络浏览器都设有阻止&nbsp;Cookie的功能。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）网站信标和图元标签</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">除</span><span style="font-family:微软雅黑">&nbsp;Cookie外，我们可能还会在网站或用户端上使用网站信标和图元标签等其他同类技术。例如，我们向您发送的电子邮件可能含有连结至我们网站内容的位址连结，如果您点击该连结，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的图元标签，我们能够获知电子邮件是否被打开。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订或按照本《隐私政策》所载明的联系方式联系我们进行处理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（三）</span><span style="font-family:微软雅黑">Do NotTrack（请勿追踪）</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">很多网络浏览器均设有</span><span style="font-family:微软雅黑">&nbsp;Do NotTrack功能，该功能可向网站发布&nbsp;Do NotTrack 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do NotTrack，那么我们的所有网站都会尊重您的选择。但请您注意，可能因此影响您使用本平台提供产品及服务的体验。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">三、我们如何共享、转让、公开披露您的个人信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）共享</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您理解并同意，我们将确保资料接收方无法重新识别个人信息主体的前提下，通过共享的方式提供给协力厂商。我们可能在以下情况下与本平台以外的任何公司、组织和个人分享您的个人信息：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、我们可能会根据法律法规规定，或按行政主管部门的强制性要求，对外共享您的个人信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、与我们的关联公司共享：您的个人信息可能会与本平台的关联公司共享。我们只会共享必要的个人信息，且受本《隐私政策》中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和使用者体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">目前，我们的授权合作伙伴包括以下两大类型：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">）广告、分析服务类的授权合作伙伴</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。除非得到您的许可，否则我们不会将您的个人身份信息（指足以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴分享。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2）</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">供应商、服务提供者和其他合作伙伴</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。我们将信息发送给支援我们业务的供应商、服务提供者和其他合作伙伴，例如代表我们推送通知的通讯服务提供者、提供认证服务的通讯服务提供者。这些支援包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利及其他为本平台产品及服务提供的支援。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）转让</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们不会将使用者的个人信息转让给任何公司、组织和个人，但按照中华人民共和国的法律法规要求必须转让的除外，且我们会按照法律法规所要求的标准继续保护或要求新的管理者按照本政策继续保护使用者的个人信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（三）公开披露</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们仅会在以下情况下，公开披露您的个人信息：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、获得您明确同意后；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、与国家安全、国防安全有关的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、与公共安全、公共卫生、重大公共利益有关的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、与犯罪侦查、起诉、审判和判决执行等有关的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">5、出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">6、所收集的信息是您自行向社会公众公开的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">7、从合法公开披露的信息中收集信息的，如合法的新闻报导、政府信息公开等管道；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">8、根据您的要求签订合同所必需的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">9、用于维护本平台服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">10、为合法的新闻报导所必需的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">11、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">12、法律法规规定的其他情形。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">四、我们如何储存和保护信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们仅在本《隐私政策》所述目的所必需期间和法律法规要求的时限内保留您的信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们将采取以下手段保护您的信息：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）资料安全技术措施</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">例如，我们使用加密技术（例如，</span><span style="font-family:微软雅黑">TLS、SSL）、匿名化处理等手段来保护您的个人信息。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）我们为保护信息采取的其他安全措施</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们通过建立资料分类分级制度、资料安全管理规范、资料安全开发规范来管理规范信息的存储和使用</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们通过信息接触者保密协定、监控和审计机制来对资料进行全面安全控制。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（三）我们仅允许有必要知晓这些信息的本平台员工、合作伙伴访问您的信息，并为此设置了严格的存取权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与本平台的合作关系。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（四）我们会采取一切合理可行的措施，确保未收集无关的信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（五）</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">互联网并非绝对安全的环境，而且电子邮件、实时通讯、社交软件或其他服务软件等与其他使用者的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（七）安全事件处置</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在通过本平台服务与协力厂商进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政位址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全性漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急回应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入本平台服务所用的系统和通讯网路，有可能因我们可控范围外的情况而发生问题。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">五、我们可能向您发送的信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）信息推送</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您在使用我们的服务时，我们可能向您发送</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">电子邮件、短信、彩信、信息或推送通知</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您可以按照我们的相关提示，在设备上选择取消订阅。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）与服务有关的公告</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们可能在必要时（例如，因系统维护而暂停某一项服务时）向您发出与服务有关的公告。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">您可能无法取消这些与服务有关、性质不属于广告的公告。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">六、您如何访问和管理自己的个人信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（一）访问您的个人信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、您有权访问您的个人信息，法律法规规定的例外情况除外。如果您希望访问或编辑您的账户中的个人基本资料信息，您可以通过登录您的账号执行此类操作。您可随时查询并管理您账户下的个人信息，账号名称是您重要的网络身份标识，出于安全性和身份识别的考虑，在注册之后无法变更。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、如果您无法通过上述路径访问该等个人信息，您可以随时通过我们客服与我们取得联系。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们将尽快回复您的访问请求。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“第（七）款回应您的上述请求”中的相关安排向您提供。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）更正或补充您的个人信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过</span><span style="font-family:微软雅黑">“（一）访问您的个人信息”中列明的方式提出更正或补充申请。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（三）删除您的个人信息</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、您可以通过“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、在以下情形中，您可以向我们提出删除个人信息的请求：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）如果我们处理个人信息的行为违反法律法规；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）如果我们收集、使用您的个人信息，却未征得您的明确同意；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）如果我们处理个人信息的行为严重违反了与您的约定；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">4）如果您不再使用我们的产品或服务，或您主动登出了账号；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">5）如果我们永久不再为您提供产品或服务。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（四）改变您授权同意的范围</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">每个业务功能需要一些基本的个人信息才能得以完成（见本《隐私权政策》第一条）。除此之外，您可以通过解除绑定、修改个人设置、删除相关信息等方式撤回部分授权，也可以通过关闭功能的方式撤销部分授权。例如，若您在使用地理位置相关服务时，希望停止分享您的地理位置信息，您可通过手机定位关闭功能、软硬件服务商及通讯服务提供者的关闭方式停止分享。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（五）个人信息主体登出账户</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、您可以自行在“登出账户”页面提交账户登出申请。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（六）约束信息系统自动决策</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害本产品商业秘密或其他使用者权益、社会公共利益的前提下提供申诉方法。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（七）回应您的上述请求</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">1、为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">2、我们将尽快做出答覆。如您不满意，还可以通过本隐私政策第九条载明的客服联系方式发起投诉。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">3、</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">4、在以下情形中，按照法律法规要求，我们将无法回应您的请求：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">1）与国家安全、国防安全有关的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">2）与公共安全、公共卫生、重大公共利益有关的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">3）与犯罪侦查、起诉、审判和执行判决等有关的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">4）有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（</span><span style="font-family:微软雅黑">6）涉及商业秘密的。</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">七、广告服务</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">本平台服务可能使用您的相关信息，向您提供与您更加相关的广告，如可能连结至协力厂商提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">（一）您可利用</span><span style="font-family:微软雅黑">“分享”键将某些内容分享到本平台服务，或您可利用协力厂商服务登录本平台服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的计算机装置COOKIES，从而正常运行上述功能；</span></span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（二）我们通过广告或我们服务的其他方式向您提供连结，使您可以接入协力厂商的服务或网站；</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">（三）其他接入协力厂商服务的情形。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">该等协力厂商社交媒体或其他服务由相关的协力厂商负责运营。您使用该等协力厂商的社交媒体服务或其他服务（包括您向该等协力厂商提供的任何信息），须受协力厂商自己的服务条款及信息保护声明（而非本《隐私政策》）约束，您需要仔细阅读其条款。本《隐私政策》仅适用于我们所收集的任何信息，并不适用于任何协力厂商提供的服务或协力厂商的信息使用规则，而我们对任何协力厂商使用由您提供的信息不承担任何责任。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">如您不希望我们将您的个人信息用作前述广告用途，您可以通过我们在广告中提供的相关提示，或在特定服务中提供的指引，要求我们停止为上述用途使用您的个人信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">八、未成年人限制</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px"><span style="font-family:微软雅黑">任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称</span><span style="font-family:微软雅黑">&quot;监护人&quot;）的同意。</span></span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们将根据国家相关法律法规的规定保护未成年人的相关信息。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们鼓励父母或监护人指导未成年人使用本平台服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交信息之前寻求父母或监护人的同意和指导。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">九、通知及变更</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们可能适时修改本《隐私政策》的条款，该等修改构成本《隐私政策》的一部分。对于重大变更，我们会提供更显著的通知，您可以选择停止使用本平台服务；在该种情况下，如您仍然继续使用本平台服务的，即表示同意受经修订的本《隐私政策》的约束。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">任何修改都会将您的满意度置于首位。我们鼓励您在每次使用本平台服务时都查阅我们的隐私政策。</span><span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">&nbsp;</span>
</p>
<p style=";text-indent: 28px;padding: 0">
    <span style="font-family: 微软雅黑;letter-spacing: 0;font-size: 12px">最后，您必须对您的账号和密码信息负有保密义务。任何情况下，请小心妥善保管。</span>
</p>
<p>
    <br/>
</p>
`