/*
 * @Author: 脉迈
 * @Date: 2021-11-10 10:38:00
 * @LastEditors: 脉迈
 * @LastEditTime: 2022-02-14 17:52:53
 */
import React from 'react';
import Index from './pages/index/index'
import About from './pages/about/index'
import Recharge from './pages/recharge/index'
import Yhxy from './pages/yhxy/index'
import Yszc from './pages/yszc/index'
import Yhxych from './pages/yhxych/index'
import Yhxydh from './pages/yhxydh/index'
import {
  Switch,
  Route
} from "react-router-dom";
function App() {
  return (
    <div>
      <Switch>
        <Route path="/" component={Index} exact/>
        <Route path="/index" component={Index} exact/>
        <Route path="/about" component={About} exact/>
        <Route path="/us" component={About} exact/>
        <Route path="/recharge" component={Recharge} exact/>
        <Route path="/yhxy" component={Yhxy} exact/>
        <Route path="/yhxy1" component={Yhxy} exact/>
        <Route path="/yszc" component={Yszc} exact/>
        <Route path="/yszc1" component={Yszc} exact/>
        <Route path="/Yhxych" component={Yhxych} exact/>
        <Route path="/yhxydh" component={Yhxydh} exact/>
      </Switch>
    </div>
      
  );
}

export default App;
