/*
 * @Author: 脉迈
 * @Date: 2021-11-10 11:15:33
 * @LastEditors: 脉迈
 * @LastEditTime: 2022-02-14 18:05:56
 */
/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styles from './TopBar.module.scss';
import Logo from '../../static/image/logo.png';
import user from '../../static/image/user.png';
import {NavLink, Link, useLocation} from 'react-router-dom';
import Kefu from '../../components/Kefu/Kefu';
import Login from '../../components/Login/Login';
import intl from 'react-intl-universal';
import { getLocal, saveLocal } from '../../utils/index';
const locales = {
  "hant": require('../../locales/zh-Hant.json'), // 繁体
  "hans": require('../../locales/zh-Hans.json'), // 简体
};
function TopBar(props: any) {
  let location = useLocation();
  const [selectLanguage, setSelectLanguage] = useState(false)
  const [showKefu,setShowKefu] = useState(false)
  const [showLogin,setShowLogin] = useState(false)
  const [initDone, setInitDone] = useState(false)
// 初始化语言
const loadLocales = () =>{
  let lang = getLocal('lang') // 读取当前的语言设置
  switch (lang) {
    case 'hans':
      intl.init({
        currentLocale: 'hans', // TODO: determine locale here
        locales
      }).then(()=>{
        setInitDone(true)
        saveLocal('lang','hans')
      })
      break;
    case 'hant':
      intl.init({
        currentLocale: 'hant', // TODO: determine locale here
        locales
      }).then(()=>{
        setInitDone(true)
        saveLocal('lang','hant')
      })
      break;
    case 'en':
      intl.init({
        currentLocale: 'en', // TODO: determine locale here
        locales
      }).then(()=>{
        setInitDone(true)
        saveLocal('lang','en')
      })
      break;
  
    default:
        intl.init({
          currentLocale: 'hant', // TODO: determine locale here
          locales
        }).then(()=>{
          setInitDone(true)
          saveLocal('lang','hant')
        })
      break;
  }

}
const setLang = (language:string) => {
  saveLocal('lang',language)
  window.location.reload();
}
useEffect(()=>{
  loadLocales()
  console.log(location)
})
  return (
    <>
      <div className={styles['top-bg']}>
        <div className={styles['bar']}>
          <Link to="/" className={styles['logo']}>
            <img src={Logo} alt="" />
          </Link>
          <div className={styles['nav']}>
            <ul>
              <li>
                <NavLink to={location.pathname === '/' || location.pathname === '/recharge'  ||  location.pathname === '/about' ? "/" : "/index"} activeClassName={styles.active} exact>
                  {intl.get("1001")}
                </NavLink>
              </li>
              <li>
                <NavLink to={location.pathname === '/index' || location.pathname==='/yszc1' ||  location.pathname === '/yhxy1' ||  location.pathname === '/us' ? "/us" : "/about"} activeClassName={styles.active} exact>
                  {intl.get("1002")}
                </NavLink>
              </li>
              {
                location.pathname === '/yhxy1' || location.pathname==='/yszc1' || location.pathname ==='/index' || location.pathname ==='/us' ? 
                <></>
                :
                <li>
                  <NavLink to="/recharge" activeClassName={styles.active} exact>
                    {intl.get("1003")}
                  </NavLink>
                </li>
              }
              
              <li onClick={()=>{setShowKefu(true)}}>
                <a href="javascript:;">{intl.get("1004")}</a>
              </li>
             
            </ul>
          </div>
          {
            window.location.pathname === '/recharge'
            ?
              <div className={styles.user}>
              <div className={styles['user-box']} onClick={()=>{setShowLogin(true)}}>
                <i className={styles.icon}/>
                <span className={styles.login}>[{intl.get('1016')}]</span>
                </div>
            </div>
              :
              <></>
          }
          
          <div className={styles.language}>
            <div className={`${selectLanguage ? `${styles['language-show']} ${styles['down']}`:styles['language-show']}`} onClick={()=>{setSelectLanguage(!selectLanguage)}}>
            Language
            <i className={styles.icon}></i>
            </div>
            {
              selectLanguage ?
                <div className={styles['language-list']}>
                  <ul>
                    <li onClick={()=>{setLang('hant')}}>中文</li>
                    {/* <li onClick={()=>{setLang('hans')}}>简体</li>
                    <li onClick={()=>{setLang('english')}}>Enginsh</li> */}
                  </ul>
                </div>
                :
                <></>
            }
          </div>
        </div>
      </div>
        
      {showKefu ? <Kefu onClose={()=>{setShowKefu(false)}}></Kefu> : <></>}
      {showLogin ? <Login onClose={()=>{setShowLogin(false)}}></Login> : <></>}
      
    </>
  )
}

export default TopBar;