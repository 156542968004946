/*
 * @Author: 蛋蛋
 * @Date: 2021-04-06 15:11:40
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-10 15:08:40
 */
import React,{useEffect,useState} from 'react'
import TopBar from '../../components/TopBar/TopBar'

import styles from './about.module.scss';
import Footer from '../../components/Footer/Footer';
import {getAboutUs} from '../../api/index';
import about1 from '../../static/image/about1.png';
import about2 from '../../static/image/about2.png';
import banner2 from '../../static/image/banner2.png';

function About() {
  const [data, setData] = useState('')
  useEffect(() => {
    getAboutUs().then((res:any)=>{
      if(res.code===0){
        setData(res.data)
      }
    })
  }, [])
  return (
    <>  
    <div className="container"> 
      <TopBar></TopBar>
      <div className={styles['about-banner']}>
        <img src={banner2} width="100%" alt=""/>
        <div className={styles.box}>
          <img src={about1} className={styles.tu} alt=""/>
          <div className={styles.desc}>
            ABOUT US
          </div>
        </div>
      </div>
      </div>
      <div className={styles['about']}>
        <div className={styles['container']}>
          <div className={styles.logo}>
            <img src={about2} alt=""/>
          </div>
          <div className={styles.content}>
            {data}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default About;