/*
 * @Author: 脉迈
 * @Date: 2021-11-10 10:38:00
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-15 19:48:15
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter
} from 'react-router-dom'
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <ScrollToTop />
        <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
