/*
 * @Author: 蛋蛋
 * @Date: 2021-04-07 18:09:19
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-15 19:35:23
 */
import React, { Fragment,useState } from 'react';
import TopBar from '../../components/TopBar/TopBar';
import Footer from '../../components/Footer/Footer';
import styles from './index.module.scss';
import gash from '../../static/image/gash.png';
import mycard from '../../static/image/mycard.png';
import gold from '../../static/image/gold.png';
function Recharge() {
  const [showRole, setShowRole] = useState(false)
  const [showServer, setShowServer] = useState(false)
  const [showGame, setShowGame] = useState(false)
  const [showGash, setShowGash] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [gameName, setGameName] = useState('')
  const [serverName, setServerName] = useState('')
  return (
    <div style={{backgroundColor:'#f1f1f1'}}>
      <TopBar></TopBar>
      <div className="container">
        <div className={styles['recharge-banner']}>
          {/* <img src={banner2} width="100%" alt=""/> */}
          <div className={styles.box}>
            <h1>儲值中心</h1>
            <div className={styles.desc}>
              TOP UP
          </div>
          </div>
        </div>
        <div className={styles['role-msg']}>
          <div className={`${styles.item} ${showGame? `${styles.active}` : ''}`}>
            <div className={`${styles.option} ${showGame? `${styles.active}` : ''}`} onClick={()=>{setShowGame(!showGame);setShowServer(false);setShowRole(false);}}>
              <span>{gameName ? gameName: '選擇遊戲'}</span>
              <i className={styles.icon}></i>
            </div>
            {
              showGame?
              <Fragment>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowGame(false);setGameName('遊戲1')}}>
                  <span>遊戲1</span>
                </div>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowGame(false);setGameName('遊戲2')}}>
                  <span>遊戲2</span>
                </div>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowGame(false);setGameName('遊戲3')}}>
                  <span>遊戲3</span>
                </div>
              </Fragment>
              :
              <></>
            }

          </div>
          <div className={`${styles.item} ${showServer? `${styles.active}` : ''}`}>
            <div className={`${styles.option} ${showServer? `${styles.active}` : ''}`} onClick={()=>{setShowServer(!showServer);setShowRole(false);setShowGame(false);}}>
              <span>{serverName ? serverName: '選擇伺服器'}</span>
              <i className={styles.icon}></i>
            </div>
            {
              showServer?
              <Fragment>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowServer(false);setServerName('區服1')}}>
                  <span>區服1</span>
                </div>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowServer(false);setServerName('區服2')}}>
                  <span>區服2</span>
                </div>
              </Fragment>
              :
              <></>
            }

          </div>
          <div className={`${styles.item}  ${showRole? `${styles.active}` : ''}`}>
            <div className={`${styles.option} ${showRole? `${styles.active}` : ''}`}  onClick={()=>{setShowRole(!showRole);setShowGame(false);setShowServer(false)}}>
              <span>{roleName ? roleName: '選擇角色'}</span>
              <i className={styles.icon}></i>
            </div>
            {
              showRole ?

              <Fragment>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowRole(false);setRoleName('角色1')}}>
                  <span>角色1</span>
                </div>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowRole(false);setRoleName('角色2')}}>
                  <span>角色2</span>
                </div>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowRole(false);setRoleName('角色3')}}>
                  <span>角色3</span>
                </div>
                <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowRole(false);setRoleName('角色4')}}>
                  <span>角色4</span>
                </div>
              </Fragment>
              :
              <></>
            }
          </div>
        </div>
        <div style={{backgroundColor:"#fff",padding:"50px 0",height:"400px"}}>
          <div className={styles['container']}>
            <div className={styles['title']}>選擇管道</div>
          </div>
          <div className={`${styles['role-msg']} ${styles['pay-ment']}` } style={{background:'#fff', paddingTop:0}}>
            <div className={`${styles.item}`}>
              <div className={`${styles.option} ${styles.titleOption} ${showGash ? styles.active: ''}`}>
                <img src={gash} width="111px" height="31px" alt=""/>
                <i className={styles.kicon} onClick={()=>{setShowGash(!showGash)}}></i>
              </div>
              {
                showGash ?
                <div className={styles.list}>
                  <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowGame(false);setGameName('遊戲1')}}>
                    <span>GASH點卡（TW）</span>
                    <i className={styles.icon}></i>
                  </div>
                  <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowGame(false);setGameName('遊戲2')}}>
                    <span>GASH會員轉點（TW）</span>
                    <i className={styles.icon}></i>
                  </div>
                  <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowGame(false);setGameName('遊戲3')}}>
                    <span>GASH會員轉點（海外）</span>
                    <i className={styles.icon}></i>
                  </div>
                  <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowGame(false);setGameName('遊戲3')}}>
                    <span>GASH手機市話</span>
                    <i className={styles.icon}></i>
                  </div>
                  <div className={`${styles.option} ${styles.options}`} onClick={()=>{setShowGame(false);setGameName('遊戲3')}}>
                    <span>GASH信用卡</span>
                    <i className={styles.icon}></i>
                  </div>
                </div>
                :
                <></>
              }

            </div>
          
            <div className={`${styles.item}`}>
              <div className={`${styles.option} ${styles.titleOption}`}>
                <img src={mycard} width="114px" height="47px" alt=""/>
                <i className={styles.kicon} ></i>
              </div>
            </div>
            <div className={`${styles.item}`}>
              <div className={`${styles.option} ${styles.titleOption} `}>
                <img src={gold} width="106px" height="45px" alt=""/>
                <i className={styles.kicon}></i>
              </div>
            </div>
          
          
          </div>

        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Recharge